import(/* webpackMode: "eager", webpackExports: ["ConfigProvider"] */ "__barrel_optimize__?names=ConfigProvider!=!D:\\Jenkins\\workspace\\ITDCS\\JCEW.CAMP\\ITDCS_JCEW.CAMP_CRC_Build\\node_modules\\antd\\es\\index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\Jenkins\\workspace\\ITDCS\\JCEW.CAMP\\ITDCS_JCEW.CAMP_CRC_Build\\node_modules\\@ant-design\\nextjs-registry\\es\\AntdRegistry.js");
;
import(/* webpackMode: "eager" */ "D:\\Jenkins\\workspace\\ITDCS\\JCEW.CAMP\\ITDCS_JCEW.CAMP_CRC_Build\\node_modules\\next\\dist\\client\\script.js");
;
import(/* webpackMode: "eager" */ "D:\\Jenkins\\workspace\\ITDCS\\JCEW.CAMP\\ITDCS_JCEW.CAMP_CRC_Build\\src\\app\\globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\Jenkins\\workspace\\ITDCS\\JCEW.CAMP\\ITDCS_JCEW.CAMP_CRC_Build\\src\\components\\BackTop\\index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\Jenkins\\workspace\\ITDCS\\JCEW.CAMP\\ITDCS_JCEW.CAMP_CRC_Build\\src\\components\\Footer\\index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\Jenkins\\workspace\\ITDCS\\JCEW.CAMP\\ITDCS_JCEW.CAMP_CRC_Build\\src\\components\\Header\\index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\Jenkins\\workspace\\ITDCS\\JCEW.CAMP\\ITDCS_JCEW.CAMP_CRC_Build\\src\\hooks\\useGlobalStore.tsx");

'use client';
import { useCallback, useEffect, useState } from 'react';
import { Locale, i18n } from './i18n.config';
import { useParams } from 'next/navigation';
// import { cookies } from "next/headers";

// We enumerate all locales here for better linting and typescript support
// We also get the default import for cleaner types
const locales: any = {
    'en-US': {
        common: () => import('./locales/en-us/common.json').then((module) => module.default),
        // 'homepage': () => import('./locales/en-us/homepage.json').then((module) => module.default),
        // 'our-impact': () => import('./locales/en-us/our-impact/index.json').then((module) => module.default),
        // 'components-listing': () => import('./locales/en-us/components-listing/index.json').then((module) => module.default),
        // 'inclusive-education': () => import('./locales/en-us/our-impact/[ourImpactDetail]/social-topic.json').then((module) => module.default),
        // 'event-details': () => import('./locales/en-us/get-involved/event/[eventDetail]/index.json').then((module) => module.default),
        // 'priority-areas': () => import('./locales/en-us/our-impact/[ourImpactDetail]/priority-areas.json').then((module) => module.default),
    },
    'zh-HK': {
        common: () => import('./locales/zh-hk/common.json').then((module) => module.default),
        // 'homepage': () => import('./locales/zh-hk/homepage.json').then((module) => module.default),
        // 'our-impact': () => import('./locales/zh-hk/our-impact/index.json').then((module) => module.default),
        // 'components-listing': () => import('./locales/zh-hk/components-listing/index.json').then((module) => module.default),
        // 'inclusive-education': () => import('./locales/zh-hk/our-impact/[ourImpactDetail]/social-topic.json').then((module) => module.default),
        // 'event-details': () => import('./locales/zh-hk/get-involved/event/[eventDetail]/index.json').then((module) => module.default),
        // 'priority-areas': () => import('./locales/zh-hk/our-impact/[ourImpactDetail]/priority-areas.json').then((module) => module.default),
    },
    // 'zh-SC': {
    // common: () => import('./locales/zh-sc/common.json').then((module) => module.default),
    // 'homepage': () => import('./locales/zh-hk/homepage.json').then((module) => module.default),
    // 'our-impact': () => import('./locales/zh-hk/our-impact/index.json').then((module) => module.default),
    // 'components-listing': () => import('./locales/zh-hk/components-listing/index.json').then((module) => module.default),
    // 'inclusive-education': () => import('./locales/zh-hk/our-impact/[ourImpactDetail]/social-topic.json').then((module) => module.default),
    // 'event-details': () => import('./locales/zh-hk/get-involved/event/[eventDetail]/index.json').then((module) => module.default),
    // 'priority-areas': () => import('./locales/zh-hk/our-impact/[ourImpactDetail]/priority-areas.json').then((module) => module.default),
    // },
};

const getTranslation = (locale: Locale, namespace: string[]) => {
    // const path =  `./locales/${locale}/${ns}.json`
    // console.log('ns path: ', path);
    // const { default: resources } = await import(/* webpackIgnore: true */ path);

    return new Promise<any>((resolve) => {
        const array: any[] = [];

        namespace.map(async (ns, i) => {
            const data: any = await locales[locale][ns]();
            // console.log('data: ', i, data);
            array.push(data);

            if (i === namespace.length - 1) {
                resolve(Object.assign({}, ...array));
            }
        });
    });
};

export const useTranslation = (namespace: string[] = [i18n.defaultNameSpace]) => {
    const [transData, setTransData] = useState<any>();
    // console.log('transData', transData);
    const params: { lang: Locale } = useParams();

    const getTransData = useCallback(async () => {
        const transitionData: any = await getTranslation(params.lang, namespace);
        setTransData(transitionData);
    }, [params.lang, namespace]);

    useEffect(() => {
        getTransData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const t = (key: string): any => {
        const array = key.split('.');
        let data = transData;

        array.forEach((text: string, i: number) => {
            if (data && data[text]) {
                data = data[text];
            } else {
                data = key;
                return false;
            }
        });

        return data || '';
    };

    return {
        t,
        i18n,
    };
};

'use client';

import cn from 'classnames';
import { Image } from 'antd';
import styles from './index.module.scss';
import { useGlobalStore, FONT_TYPE } from '@/hooks/useGlobalStore';
import { useTranslation } from '@/i18n/use-transition.client';
import { SocialMediaFragment } from '@/@types/generated/sitecore';
import { sharingFacebook, sharingTwitter, withFullPath } from '@/utils/utils';
import { WATrackerClickEvn } from '@/utils/wa';

type Props = {
    footerSocialMediaData?: SocialMediaFragment[];
};

const FontSizeSelector = (props: Props) => {
    const { footerSocialMediaData } = props;
    const globalStore = useGlobalStore();
    const { t } = useTranslation();
    const { globalFontType, onGlobalFontSizeChange } = globalStore;
    const onFontChange = (type: keyof typeof FONT_TYPE) => {
        WATrackerClickEvn(`cs_font_${type[0]?.toLowerCase()}`);
        onGlobalFontSizeChange(type);
    };
    const faceBookSrc = footerSocialMediaData?.find(
        (i) => i.socialName?.value === 'FooterFaceBook' || i.name === 'FooterFaceBook',
    )?.socialIcon?.src;
    const faceBookLink =
        footerSocialMediaData?.find(
            (i) => i.socialName?.value === 'FooterFaceBook' || i.name === 'FooterFaceBook',
        )?.socialLink?.value || '';
    const twitterSrc = footerSocialMediaData?.find(
        (i) => i.socialName?.value === 'FooterTwitter' || i.name === 'FooterTwitter',
    )?.socialIcon?.src;
    const twitterLink =
        footerSocialMediaData?.find(
            (i) => i.socialName?.value === 'FooterTwitter' || i.name === 'FooterTwitter',
        )?.socialLink?.value || '';
    return (
        <div className={cn(styles.FontSizeSelector, 'flex justify-center')}>
            <div className={cn('container social-margin max-w-[1170px]')}>
                <div className='fontFlex'>
                    {globalStore.language === 'zh-HK' && (
                        <div
                            className='iconsContainer cursor-pointer'
                            onClick={() => sharingFacebook(faceBookLink)}
                        >
                            <Image
                                preview={false}
                                src={withFullPath(faceBookSrc || '')}
                                alt=''
                                width={23}
                                height={23}
                            />
                        </div>
                    )}

                    <div
                        className='iconsContainer cursor-pointer ml-[5px]'
                        onClick={() => sharingTwitter(twitterLink)}
                    >
                        <Image
                            preview={false}
                            src={withFullPath(twitterSrc || '')}
                            alt=''
                            width={23}
                            height={23}
                        />
                    </div>

                    <div className='fontSizeSe'>
                        <div className='text-xs mr-2'>{t('FontSize')}</div>
                        <div
                            className={cn(
                                'selectItem small p-[5px] cursor-pointer',
                                globalFontType === FONT_TYPE.SMALL && 'active',
                            )}
                            onClick={() => onFontChange('SMALL')}
                        >
                            T
                        </div>
                        <div
                            className={cn(
                                'selectItem medium p-[5px] cursor-pointer',
                                globalFontType === FONT_TYPE.MEDIUM && 'active',
                            )}
                            onClick={() => onFontChange('MEDIUM')}
                        >
                            T
                        </div>
                        <div
                            className={cn(
                                'selectItem large p-[5px] cursor-pointer',
                                globalFontType === FONT_TYPE.LARGE && 'active',
                            )}
                            onClick={() => onFontChange('LARGE')}
                        >
                            T
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FontSizeSelector;

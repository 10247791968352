import { NextRequest } from 'next/server';
import { WATrackerClickEvn } from './wa';

export const isRichText = (str: string) => {
    const htmlRegex = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
    return htmlRegex.test(str);
};

export const mediaStringFormat = (dataString: string, req: NextRequest) => {
    try {
        const mediaDomain = new URL(process.env.SITECORE_GRAPH_URL).origin;
        const mediaBaseUrl = mediaDomain || `${req.nextUrl.protocol}//${req.nextUrl.host}`;
        const result = dataString.replace(/\/?(-\/media\/)/g, `${mediaBaseUrl}/-/media/`);
        return JSON.parse(result);
    } catch (e) {
        console.error('mediaStringFormat error: ', e);
    }
};

export function withBasePath(subpath: string): string {
    const APP_BASE_PATH = process.env.NEXT_PUBLIC_APP_BASE_PATH;
    if (APP_BASE_PATH) {
        const path = `${APP_BASE_PATH ? `/${APP_BASE_PATH}` : ''}${subpath}`;
        return path;
    } else {
        return subpath;
    }
    // return subpath;
}

export function withFullPath(path?: string): string {
    if (!path) {
        return path || '';
    }
    if (path.startsWith('http:') || path.startsWith('https:')) {
        return path;
    }
    const MEDIA_FULL_PATH = /(?=.*\/\/)(?=.*-\/media\/).*$/;
    if (MEDIA_FULL_PATH.test(path)) {
        return `https:${path}`;
    }
    return path;
}

/** 是否在时间范围内
 * startDate--开始时间
 * endDate----结束时间
 */
export const isTimeFrame = (startDate: number, endDate: number) => {
    if (startDate <= 0 && endDate <= 0) {
        return true;
    }
    if (endDate < 0) {
        endDate = Number.MAX_SAFE_INTEGER;
    }
    const date = new Date().getTime();
    return date >= startDate && date < endDate;
};

/** 移动端尺寸 */
export const mobileBreakPoint = 992;

/** 过滤crossSellBanner对象 */
export const crossSellBannerData = (contentData: any) => {
    const content = contentData?.row
        .flatMap((row: any) => row.col)
        .flatMap((col: any) => col.pageContent)
        .filter((content: any) => content?.template?.name === 'CrossSellBanner');
    return content;
};

export function toCamelCase(str: string) {
    return str.replace(/[-_\s](.)/g, function (match, group1) {
        return group1.toUpperCase();
    });
}

export const isValidUrl = (urlString?: string) => {
    if (!urlString) return false;
    const urlPattern = new RegExp(
        '^(https?:\\/\\/)?' + // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
            '(\\#[-a-z\\d_]*)?$',
        'i',
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
};

function removeURLParameter(url: string, parameter: string) {
    var urlparts = url.split('?');
    if (urlparts.length >= 2) {
        var prefix = encodeURIComponent(parameter) + '=';
        var pars = urlparts[1].split(/[&;]/g);

        for (var i = pars.length; i-- > 0; ) {
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }
        url = urlparts[0] + '?' + pars.join('&');
        if (url.split('?')[1] != '') {
            return url;
        } else {
            return url.replace('?', '');
        }
    } else {
        if (url.split('?')[1] != '') {
            return url;
        } else {
            return url.replace('?', '');
        }
    }
}

function removeInject(InStr: string) {
    InStr = InStr.replace(/\</g, '');
    InStr = InStr.replace(/\>/g, '');
    InStr = InStr.replace(/\"/g, '');
    InStr = InStr.replace(/\'/g, '');
    InStr = InStr.replace(/\;/g, '');
    InStr = InStr.replace(/\(/g, '');
    InStr = InStr.replace(/\)/g, '');
    return InStr;
}

export const sharingTwitter = async (extLink: string) => {
    if (!extLink) return;
    const trackingCode = '_1617CTC';
    const ogTitle =
        typeof document !== undefined
            ? (document?.querySelector('meta[property="og:title"]') as HTMLMetaElement)?.content
            : 'The Hong Kong Jockey Club';
    let TwitterHashTag = '&hashtags=HKJC';
    const login = 'hkjc';
    const api_key = 'R_d8fea1c6a4629793be509bf7edfd4708';
    const pageTitle = encodeURIComponent(ogTitle);
    // const extLink = 'http://twitter.com/intent/tweet?text=';
    let shortURL = 'http://www.hkjc.com';

    WATrackerClickEvn('btn_shareTwitter' + trackingCode);
    var currentPath =
        location.protocol +
        '//' +
        location.host +
        location.pathname +
        (location.search ? location.search : '');

    currentPath = removeURLParameter(currentPath, 'b_cid');
    currentPath = removeURLParameter(currentPath, 'cid');

    if (currentPath.indexOf('?') > 0) {
        currentPath = removeInject(currentPath + '&cid=TWVEPOA' + trackingCode);
    } else {
        currentPath = removeInject(currentPath + '?cid=TWVEPOA' + trackingCode);
    }

    fetch(
        `${location.protocol}//api-ssl.bitly.com/v3/shorten?format=json&apiKey=${api_key}&login=${login}&longUrl=${currentPath}`,
    ).then(async (response) => {
        if (response?.ok) {
            const result = await response.json();
            if (typeof result.data == 'undefined') {
                shortURL = '//www.hkjc.com';
            } else if (typeof result.data.url == 'undefined') {
                shortURL = '//www.hkjc.com';
            } else {
                shortURL = result.data.url;
            }
        }
    });

    window.open(
        extLink +
            pageTitle +
            '&url=' +
            encodeURIComponent(shortURL) +
            '&via=HKJC_Racing' +
            TwitterHashTag,
    );
};

export const sharingFacebook = (extLink: string) => {
    if (!extLink) return;
    const trackingCode = '_1617CTC';
    const ogTitle =
        typeof document !== undefined
            ? (document?.querySelector('meta[property="og:title"]') as HTMLMetaElement)?.content
            : 'The Hong Kong Jockey Club';
    const pageTitle = encodeURIComponent(ogTitle);
    // const extLink = 'https://www.facebook.com/sharer.php?u=';

    WATrackerClickEvn('btn_shareFacebook' + trackingCode);

    let currentPath =
        location.protocol +
        '//' +
        location.host +
        location.pathname +
        (location.search ? location.search : '');

    currentPath = removeURLParameter(currentPath, 'b_cid');
    currentPath = removeURLParameter(currentPath, 'cid');

    if (currentPath.indexOf('?') > 0) {
        currentPath = removeInject(currentPath + '&cid=FBVEPOA' + trackingCode);
    } else {
        currentPath = removeInject(currentPath + '?cid=FBVEPOA' + trackingCode);
    }

    window.open(extLink + encodeURIComponent(currentPath) + '&t=' + pageTitle);
};

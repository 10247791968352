'use client';

import React, { useState } from 'react';
import { FloatButton } from 'antd';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons';

const BackTop = () => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div className={styles.iconDiv}>
            <FloatButton.BackTop
                shape='circle'
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                icon={
                    <div className={`${isHovered && 'opacity-80'} iconTop`}>
                        <FontAwesomeIcon
                            icon={faArrowCircleUp}
                            style={{ fontSize: '40px', color: '#c6c6c6' }}
                        />
                    </div>
                }
            />
        </div>
    );
};

export default BackTop;

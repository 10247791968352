'use client';

import {
    createContext,
    ReactNode,
    useContext,
    useState,
    useEffect,
    Dispatch,
    SetStateAction,
    useRef,
} from 'react';
import { usePathname } from 'next/navigation';
import withTheme from '@/theme';
import { i18n, Locale } from '@/i18n/i18n.config';
import { WATrackerTrackPageView } from '@/utils/wa';

// import { GlobalSettingQueryResult } from '@/@types/generated/sitecore';

export enum FONT_TYPE {
    SMALL = -2,
    MEDIUM = 0,
    LARGE = 2,
}

export enum LANGUAGE {
    'en-US' = 'en-US',
    'zh-HK' = 'zh-HK',
}

type NewsItem = {
    year: string;
    month: string;
    pageNo: number;
};

export type NewsImgGroup = { id: number; src: string; mediaCaption: string }[];

type StoreType =
    | {
          globalFontType: FONT_TYPE;
          fontFamily: string;
          onGlobalFontSizeChange: (type: keyof typeof FONT_TYPE) => void;
          language: 'en-US' | 'zh-HK';
          setLanguage: Dispatch<SetStateAction<Locale>>;
          setIsLoading: Dispatch<SetStateAction<boolean>>;
          isLoading: boolean;
          newsItem: NewsItem;
          setNewsItem: Dispatch<SetStateAction<NewsItem | undefined>>;
          newsImgGroupVisible: boolean;
          setNewsImgGroupVisible: Dispatch<SetStateAction<boolean>>;
          newsImgGroup: NewsImgGroup;
          setNewsImgGroup: Dispatch<SetStateAction<NewsImgGroup>>;
          newsImgIndex: number | undefined;
          setNewsImgIndex: Dispatch<SetStateAction<number | undefined>>;
          pageID?: string;
      }
    | undefined;

let storeValue: StoreType = undefined;

export const GlobalContext = createContext<StoreType>(storeValue);

export default function GlobalProvider({ children, data }: { children: ReactNode; data: any }) {
    const pathname = usePathname();
    const [globalFontType, setGlobalFontType] = useState(FONT_TYPE.MEDIUM);
    const [language, setLanguage] = useState<Locale>(i18n.defaultLocale);
    const onGlobalFontSizeChange = (type: keyof typeof FONT_TYPE) => {
        setGlobalFontType(FONT_TYPE[type]);
    };
    const [isLoading, setIsLoading] = useState(true);
    const [newsItem, setNewsItem] = useState<NewsItem>();
    const [newsImgGroup, setNewsImgGroup] = useState<NewsImgGroup>([]);
    const [newsImgIndex, setNewsImgIndex] = useState<number | undefined>();
    const [newsImgGroupVisible, setNewsImgGroupVisible] = useState(false);
    const currentPath = useRef('');

    const isHome = pathname.replace(`/${language}`, '') === '/';
    const hasDetailPage = ['/news', '/album', '/video'].some((i) => pathname.includes(i));

    useEffect(() => {
        if (typeof document) {
            isHome
                ? (document.body.style.fontSize = '15px')
                : (document.body.style.fontSize = `${15 + globalFontType}px`);
        }

        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }, [globalFontType, isHome]);

    useEffect(() => {
        const currentLang = i18n.locales.find((i) => {
            const Reg = new RegExp(i);
            return pathname.match(Reg);
        });

        const regex = new RegExp(`^/${currentLang || 'en-US'}/([^/]+)/(.+)?$`);

        const pageReg = pathname.match(regex);
        const currentPageReg = currentPath?.current?.match(regex);
        const page = pageReg && pageReg?.length > 1 ? pageReg[1] : 'home';
        const currentPage =
            currentPageReg && currentPageReg?.length > 1 ? currentPageReg[1] : 'home';

        if (currentLang) {
            setLanguage(currentLang);
        }
        if ((!hasDetailPage && currentPage !== page) || !currentPath?.current) {
            if (currentPage.includes('news') && !page.includes('news')) {
                setNewsItem(undefined);
            }
            WATrackerTrackPageView(`1617CTC_${page}`);
            currentPath.current = pathname;
        }

        if (
            hasDetailPage &&
            pageReg &&
            pageReg[2] &&
            ((currentPageReg && currentPageReg[2] !== pageReg[2]) || !currentPageReg)
        ) {
            if (currentPage.includes('news') && !page.includes('news')) {
                setNewsItem(undefined);
            }
            WATrackerTrackPageView(`1617CTC_${page}`);
            currentPath.current = pathname;
        }
    }, [pathname]);

    const theme = {
        token: {
            fontSize: isHome ? 15 : 15 + globalFontType,
        },
    };
    return (
        <GlobalContext.Provider
            value={{
                ...(data || {}),
                globalFontType,
                onGlobalFontSizeChange,
                language,
                setLanguage,
                setIsLoading,
                isLoading,
                newsItem,
                setNewsItem,
                newsImgGroup,
                setNewsImgGroup,
                newsImgGroupVisible,
                setNewsImgGroupVisible,
                newsImgIndex,
                setNewsImgIndex,
            }}
        >
            {!isLoading && withTheme(<>{children}</>, theme)}
        </GlobalContext.Provider>
    );
}

export function useGlobalStore() {
    const store = useContext(GlobalContext);
    if (!store) {
        throw new Error('You have forgot to use Provider');
    }
    return store;
}

'use client';
import { withBasePath } from '@/utils/utils';
import cn from 'classnames';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import styles from './index.module.scss';
import { useGlobalStore, LANGUAGE } from '@/hooks/useGlobalStore';
import useIsMobile from '@/hooks/useIsMobile';
import { Button, ConfigProvider, Drawer, Flex, Image, Menu, MenuProps } from 'antd';
import { memo, useEffect, useMemo, useState } from 'react';
import { MenuFragment, SocialMediaFragment } from '@/@types/generated/sitecore';
import { MenuItemType } from 'antd/es/menu/interface';
import { DownOutlined } from '@ant-design/icons';
import { Locale } from '@/i18n/i18n.config';
import FollowUsOn from '../FollowUsOn';
import { useTranslation } from '@/i18n/use-transition.client';
import withTheme from '@/theme';

type Props = {
    HeaderNavigation: MenuFragment[] | [];
    socialMediaData: Array<SocialMediaFragment>;
    lang: Locale;
};

/** Navtgation */
const Header = (props: Props) => {
    const router = useRouter();
    const globalStore = useGlobalStore();
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const { isMobile } = useIsMobile();
    const { t } = useTranslation();

    const { HeaderNavigation, socialMediaData, lang } = props;

    const [navItems, setNavItems] = useState([] as any);
    const [current, setCurrent] = useState('/');
    const [logoImgShow, setLogoImgShow] = useState(true);
    const [openMobile, setOpenMobile] = useState(false);

    const handleScroll = () => {
        if (window.scrollY >= 30) {
            setLogoImgShow(false);
        } else {
            setLogoImgShow(true);
        }
    };

    const onClickNav: MenuProps['onClick'] = (e) => {
        const targetDom = (e?.domEvent?.target || (e as any)?.target) as Element;
        if (targetDom?.tagName === 'A' && e.key.endsWith('.pdf')) {
            return;
        }
        if (e.key === 'en-US' || e.key === 'zh-HK') {
            selectLang(e.key);
            return;
        }
        const isExternal = /^https?:\/\//.test(e.key);
        setCurrent(e.key);

        isExternal ? window.open(e.key) : router.push(`/${lang}${e.key}`);
    };

    const selectLang = (i: keyof typeof LANGUAGE) => {
        // console.log('i', i)
        globalStore.setLanguage(LANGUAGE[i]);
        router.push(
            `${pathname.replace(lang, i)}${searchParams.size > 0 ? '?' + searchParams.toString() : ''}`,
        );
    };

    const convertToNav = (data: MenuFragment[]): MenuItemType[] => {
        const defaultNav = !isMobile
            ? [
                  {
                      label:
                          lang === 'en-US' ? (
                              <span className='text-[#333]'>中文</span>
                          ) : (
                              <span className='text-[#333]'>ENG</span>
                          ),
                      key: lang === 'en-US' ? 'zh-HK' : 'en-US',
                  },
              ]
            : [];
        return data.reduce<MenuItemType[]>((result, item) => {
            if (item.destination?.value?.endsWith('.pdf')) {
                const menuItem: MenuItemType | any = {
                    label: (
                        <a
                            href={item.destination.value || ''}
                            target={item.linkTo?.value === 'External' ? '_blank' : '_self'}
                            id='Trackwork'
                        >
                            {item.displayText?.value}
                        </a>
                    ),
                    key: item?.destination?.value || '',
                    popupClassName: styles.desktopPopup,
                };
                item.enabled?.boolValue && result.push(menuItem);
                return result;
            }
            const menuItem: MenuItemType | any = {
                label:
                    item.name === 'home' ? (
                        <Image
                            src={withBasePath('/images/home.svg')}
                            width={20 + globalStore.globalFontType}
                            height={20 + globalStore.globalFontType}
                            alt=''
                            preview={false}
                            className='translate-y-[6px]'
                        />
                    ) : (
                        item?.displayText?.value || item.name
                    ),
                key: item?.destination?.value || '',
                popupClassName: styles.desktopPopup,
            };
            item.enabled?.boolValue && result.push(menuItem);
            return result;
        }, defaultNav);
    };

    const removeLanguagePrefix = (path: string): string => {
        const regex = /^\/(en-US|zh-HK)(\/[^/]+)(\/.*)?$/;
        const match = path.match(regex);

        if (match) {
            return `${match[2]}/`;
        }

        return path.replace(/\/$/, '/');
    };

    useEffect(() => {
        setNavItems(convertToNav(HeaderNavigation));
    }, [HeaderNavigation, globalStore.globalFontType, isMobile]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        // 清理事件监听器
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const pName = removeLanguagePrefix(pathname);
        const isHome = ['/en-US/', '/zh-HK/'].includes(pName);
        setCurrent(isHome ? '/' : pName);
    }, [pathname]);

    return withTheme(
        <>
            {isMobile ? (
                <Flex
                    align='center'
                    className={cn(styles.headerMNav, 'w-full h-[91px] bg-white px-[20px] ')}
                >
                    <Image
                        src={withBasePath('/images/cr-logo.png')}
                        width={274}
                        height={65}
                        alt=''
                        preview={false}
                    />
                    <div
                        className='fixed top-[20px] right-[10px] z-50'
                        onClick={() => setOpenMobile(true)}
                    >
                        <Image
                            src={withBasePath('/images/MenuL.svg')}
                            width={55}
                            height={50}
                            alt=''
                            preview={false}
                        />
                    </div>
                    <Drawer
                        rootClassName={styles.drawerWrap}
                        width={'80%'}
                        title=''
                        placement="right"
                        onClose={() => setOpenMobile(false)}
                        open={openMobile}
                        closable={false}
                        styles={{
                            content: { backgroundColor: '#0c2d69', padding: '0px' },
                            body: { padding: '0px' },
                        }}
                    >
                        {lang === 'en-US' ? (
                            <div
                                className='w-full h-[80px] leading-[80px] pl-[20px] text-white'
                                onClick={() => {
                                    setOpenMobile(false);
                                    selectLang('zh-HK');
                                }}
                            >
                                中文
                            </div>
                        ) : (
                            <div
                                className='w-full h-[80px] leading-[80px] pl-[20px] text-white'
                                onClick={() => {
                                    setOpenMobile(false);
                                    selectLang('en-US');
                                }}
                            >
                                Eng
                            </div>
                        )}
                        {navItems.map((i: any, index: number) => {
                            return (
                                <div
                                    key={index}
                                    onClick={(e) => {
                                        setOpenMobile(false);
                                        onClickNav({ ...e, ...i });
                                    }}
                                    className='w-full h-[50px] leading-[50px] pl-[20px] bg-[#FECF13] text-[#333] font-bold '
                                    style={{ borderBottom: ' 1px solid rgba(0, 0, 0, 0.3)' }}
                                >
                                    {i.key === '/' ? `${t('Home')}` : i.label}
                                </div>
                            );
                        })}
                        <div>
                            <FollowUsOn isMobile={true} socialMediaData={socialMediaData} />
                        </div>
                    </Drawer>
                </Flex>
            ) : (
                <div className={cn('bg-white top-0 z-50 w-full fixed')}>
                    <div className={cn(styles.headerNav, 'flex justify-center ')}>
                        <Flex vertical={false} align='flex-end' className={styles.headerFlex}>
                            <div
                                className={`hkjcLogo`}
                                style={{
                                    display: `${logoImgShow ? 'flex' : 'none'}`,
                                }}
                            >
                                <Image
                                    src={withBasePath('/images/cr-logo.png')}
                                    width={274}
                                    height={65}
                                    alt=''
                                    preview={false}
                                />
                            </div>
                            <div className='w-full pb-[5px]'>
                                <Menu
                                    className='flex-1 items-center justify-end'
                                    onClick={onClickNav}
                                    selectedKeys={[current]}
                                    mode='horizontal'
                                    items={navItems}
                                    defaultSelectedKeys={['/']}
                                    expandIcon={<DownOutlined />}
                                    inlineCollapsed={false}
                                />
                            </div>
                        </Flex>
                    </div>
                </div>
            )}
        </>,
        {
            token: {
                colorSplit: 'transparent',
            },
            components: {
                Menu: {
                    itemHeight: 66,
                    itemMarginInline: 0,
                    itemPaddingInline: 10,
                    iconMarginInlineEnd: 0,
                    itemBorderRadius: 0,
                    itemColor: '#0c2d69',
                    itemHoverColor: '#0c2d69',
                    itemSelectedColor: '#0c2d69',
                },
            },
        },
    );
};

export default memo(Header);

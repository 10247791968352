'use client';
import styles from './index.module.scss';
import { getYear } from 'date-fns';
import cn from 'classnames';
import { MenuFragment, SocialMediaFragment } from '@/@types/generated/sitecore';
import { Locale } from '@/i18n/i18n.config';
import { useTranslation } from '@/i18n/use-transition.client';
import { useRouter, usePathname } from 'next/navigation';
import { useGlobalStore } from '@/hooks/useGlobalStore';
import useIsMobile from '@/hooks/useIsMobile';
import FontSizeSelector from '../FontSizeSelector';

type Props = {
    FooterNavigation: MenuFragment[] | [];
    footerSocialMediaData: SocialMediaFragment[];
    lang?: Locale;
};

const FooterBar = (props: Props) => {
    const router = useRouter();
    const pathname = usePathname();
    const { isMobile } = useIsMobile();
    const { t } = useTranslation();
    const { FooterNavigation, footerSocialMediaData } = props;
    const globalStore = useGlobalStore();
    const lang = globalStore.language;
    const isHome = pathname.replace(`/${lang}`, '') === '/';

    const goTo = (i: MenuFragment) => {
        const isExternal = i.linkTo?.value === 'External';
        const destination = i.destination?.value ?? '';
        isExternal
            ? window.open(i.destination?.value ?? '')
            : router.push(
                  `/${lang}${destination.startsWith('/') ? destination : '/' + destination}`,
              );
    };

    return (
        <>
            {!isHome && <FontSizeSelector footerSocialMediaData={footerSocialMediaData} />}
            <div className={cn(styles.footerNav, 'flex justify-center', isMobile && '!pl-[15px]')}>
                <div className='container max-w-[1170px]'>
                    <div className='footerContent'>
                        <div className='d-block'>
                            {FooterNavigation.map((i, index) => (
                                <div key={index}>
                                    {i.enabled?.boolValue && (
                                        <button
                                            type='button'
                                            className='btn btn-link col btn'
                                            onClick={() => goTo(i)}
                                        >
                                            {i.displayText?.value ?? ''}
                                        </button>
                                    )}
                                </div>
                            ))}

                            {/* <div className='vr' style={{ transform: 'translateY(4px)' }} /> */}
                        </div>

                        <div
                            className={`${isMobile ? 'text-left leading-tight w-[85%] pt-5' : 'text-right'}`}
                        >
                            {t('copyRight')?.replace('{{year}}', getYear(new Date()))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FooterBar;

'use client';
import { withFullPath } from '@/utils/utils';
import { Flex, Image } from 'antd';
import { SocialMediaFragment } from '@/@types/generated/sitecore';
import { useTranslation } from '@/i18n/use-transition.client';
import { WATrackerClickEvn } from '@/utils/wa';

type Props = {
    isMobile?: boolean;
    socialMediaData: Array<SocialMediaFragment>;
};

const FollowUsOn = (props: Props) => {
    // console.log('FollowUsOn------------', props);
    const { t } = useTranslation();
    const { isMobile = false, socialMediaData } = props;

    return (
        <div>
            {isMobile ? (
                <div className=' py-[10px] px-[15px] '>
                    <Flex
                        vertical
                        gap={10}
                        justify='center'
                        align='baseline'
                        className='pt-[10px] pb-[5px]'
                    >
                        <div className='text-white font-bold'>{t('FollowUs')}:</div>
                        <Flex vertical={false} gap={10} className='pl-2'>
                            {socialMediaData.map((i, index) => (
                                <Image
                                    key={index}
                                    preview={false}
                                    className='cursor-pointer'
                                    height={30}
                                    src={withFullPath(i.socialIcon?.src ?? '')}
                                    alt=''
                                    onClick={() => {
                                        WATrackerClickEvn(`btn_follow${i.socialName?.value}`);
                                        window.open(i.socialLink?.value ?? '');
                                    }}
                                />
                            ))}
                        </Flex>
                    </Flex>
                </div>
            ) : (
                <div className='bg-[#656565] py-[10px] px-[15px] flex justify-center items-center'>
                    <Flex
                        vertical
                        gap={10}
                        justify='center'
                        align='center'
                        className='pt-[10px] pb-[5px]'
                    >
                        <div className='text-white font-bold'>{t('FollowUs')}:</div>
                        <Flex vertical={false} gap={10}>
                            {socialMediaData.map((i, index) => (
                                <Image
                                    key={index}
                                    preview={false}
                                    className='cursor-pointer'
                                    height={30}
                                    src={withFullPath(i.socialIcon?.src ?? '')}
                                    alt=''
                                    onClick={() => window.open(i.socialLink?.value ?? '')}
                                />
                            ))}
                        </Flex>
                    </Flex>
                </div>
            )}
        </div>
    );
};

export default FollowUsOn;

enum LangEnum {
    'en-US' = 'en-US',
    'zh-HK' = 'zh-HK',
}

export const i18n = {
    defaultLocale: 'en-US',
    locales: ['en-US', 'zh-HK'],
    defaultNameSpace: 'common',
} as const;

export type Locale = keyof typeof LangEnum;

'use client';
import { mobileBreakPoint } from '@/utils/utils';
import { useEffect, useState } from 'react';

const useIsMobile = () => {
    const [windowSize, setWindowSize] = useState({
        width: 0,
        height: 0,
    });

    function handleResize() {
        if (window.innerWidth !== windowSize.width) {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { isMobile: windowSize.width < mobileBreakPoint, windowSize };
};

export default useIsMobile;
